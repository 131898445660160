import {useRef} from 'react';
import {motion} from 'framer-motion';

// styles
import {PortfolioCardModalComponent} from './styles';

// components
import PortfolioCard from '../Card';


/**
 *
 * @param {{
 * 		portfolioItem: PortfolioItem,
 * 		modalState: string,
 * 		modalCloseDelay: number,
 * 		setModalState: function,
 * 		setSelectedItem: function,
 * 		setForegroundItem: function,
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const PortfolioCardModal = ( {
								 portfolioItem,
								 modalState,
								 modalCloseDelay,
								 setModalState,
								 setSelectedItem,
								 setForegroundItem
							 } ) =>
{
	const portfolioWrapper    = useRef( null );
	const isOpen              = !!portfolioItem;
	const closeOnClick        = e =>
	{
		if (portfolioWrapper.current === e.target) {
			e.stopPropagation();
			setModalState( 'modal-closing' );
			setTimeout( () => setSelectedItem( null ), modalCloseDelay * 1000 );
		}
	};
	const onAnimationStart    = () =>
	{
		setModalState( 'modal-opening' );
		setForegroundItem( portfolioItem );
	};
	const onAnimationComplete = () =>
	{
		setModalState( 'modal-opened' );
	};


	if (!isOpen) {
		return <></>;
	}

	return (
		<PortfolioCardModalComponent
			ref={portfolioWrapper}
			onClick={e => closeOnClick( e )}
			className={modalState}
		>
			<div className="item-wrapper">
				<motion.div
					layoutId={portfolioItem.layoutId}
					style={{ height: '100%' }}
					transition={{ duration: 0.5 }}
					animate
					onLayoutAnimationStart={() => onAnimationStart()}
					onLayoutAnimationComplete={() => onAnimationComplete()}
				>
					<PortfolioCard
						portfolioItem={portfolioItem}
						modalState={modalState}
						isModal={true}
						isForeground={true}
						setSelectedItem={setSelectedItem}
						setModalState={setModalState}
						modalCloseDelay={modalCloseDelay}
					/>
				</motion.div>
			</div>
		</PortfolioCardModalComponent>
	);
};


/**
 *
 */
export default PortfolioCardModal;