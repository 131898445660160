import styled, {css} from 'styled-components';


export const TagListComponent = styled.div`

	ul {
		display: flex;
		flex-wrap: wrap;
		margin: 0 0 1rem 0;

		li {
			border: 0.125rem solid ${( { theme } ) => theme.colors.blue};
			color: ${( { theme } ) => theme.colors.blue};
			margin: 0 0.5rem 0.5rem 0;
			padding: 0 0.5rem;
			font-size: 0.875rem;

			font-weight: 600;
			font-family: ${( { theme } ) => theme.typography.fonts.secondary};
			line-height: 1.25;

			${( { theme } ) => css`
				@media (${theme.mediaQueries.mdUp}) {
					font-size: 0.75rem;
				}
			`};
		}
	}

`;