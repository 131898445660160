import {Button} from 'react-bootstrap';

// styles
import {ButtonLinkListComponent} from './styles';


/**
 *
 * @param {{
 * 		links: AnchorLink[],
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const ButtonLinkList = ( { links } ) =>
{
	return (
		<ButtonLinkListComponent>
			{links &&
				<ul className="links">
					{links.map( ( link, key ) =>
						<li key={key}>
							<Button
								as="a"
								variant="outline-primary"
								href={link.url}
								target="_blank"
								rel="noreferrer"
							>
								{link.title}
							</Button>
						</li>
					)}
				</ul>
			}
		</ButtonLinkListComponent>
	);
};


/**
 *
 */
export default ButtonLinkList;