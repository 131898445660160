// noinspection HtmlUnknownAnchorTarget

import {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';

// styles
import {HeaderComponent} from './styles';

// components
import Hamburger from '../Hamburger';
import SmoothScrollHashLink from '../SmoothScrollHashLink';


/**
 *
 * @param {{
 * 		openContactModal: function,
 * 	}} props
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ( { openContactModal } ) =>
{
	const [smMenuOpen, setSmMenuOpen]               = useState( false );
	const [smMenuInitialized, setSmMenuInitialized] = useState( false );

	const menuLeftItems   = [
		{
			title:    'Skills',
			fragment: 'skills',
		},
		{
			title:    'Portfolio',
			fragment: 'portfolio',
		},
	];
	const menuRightItems  = [
		{
			title:    'About',
			fragment: 'about',
		},
		{
			title:    'Philosophy',
			fragment: 'philosophy',
		},
	];
	const mobileMenuItems = menuLeftItems.concat( menuRightItems );
	const openContact     = e =>
	{
		openContactModal( e );
		setSmMenuOpen( false );
	};


	useEffect( () =>
	{
		setTimeout( () => setSmMenuInitialized( true ), 600 );
	} );

	return (
		<HeaderComponent>
			<header>
				<Container>
					<div className="d-block d-md-none">
						<div
							className={`sm-menu ${smMenuOpen ? 'menu-open' : 'menu-closed'} ${smMenuInitialized ? 'initialized' : ''}`}
						>
							<div className="logo">
								<SmoothScrollHashLink path="/" hash="top">
									<img src="/images/logos/afwd-horizontal-darkmode.svg"
										 alt="Aaron Fisher Web Development, LLC" />
								</SmoothScrollHashLink>
							</div>
							<Hamburger
								setOpen={setSmMenuOpen}
								open={smMenuOpen}
							/>
							<nav className={`primary ${smMenuInitialized ? 'initialized' : ''}`}>
								<div className="box">
									<ul>
										{mobileMenuItems.map( ( item, key ) =>
											<li key={key}>
												<SmoothScrollHashLink
													path="/"
													hash={item.fragment}
													onClick={() => setSmMenuOpen( false )}
													className={`n-${key}`}
												>
													{item.title}
												</SmoothScrollHashLink>
											</li>
										)}
										<li>
											<a href="#contact" onClick={openContact}
											   className={`n-${mobileMenuItems.length}`}>
												Contact
											</a>
										</li>
									</ul>
								</div>
							</nav>
						</div>
					</div>
					<div className="d-none d-md-block">
						<div className="lg-menu">
							<nav className="primary">
								<ul>
									{menuLeftItems.map( ( item, key ) =>
										<li key={key}
											className={(key === menuLeftItems.length - 1) ? 'left-of-logo' : ''}>
											<SmoothScrollHashLink path="/" hash={item.fragment}>
												{item.title}
											</SmoothScrollHashLink>
										</li>
									)}
									<li className="logo">
										<SmoothScrollHashLink path="/" hash="top">
											<img src="/images/logos/afwd-logo-mark.svg" alt="AF" />
										</SmoothScrollHashLink>
									</li>
									{menuRightItems.map( ( item, key ) =>
										<li key={key} className={(key === 0) ? 'right-of-logo' : ''}>
											<SmoothScrollHashLink path="/" hash={item.fragment}>
												{item.title}
											</SmoothScrollHashLink>
										</li>
									)}
								</ul>
							</nav>
							<div className="secondary">
								<p className="address">
									<span>7804 E. Funston Ste 216</span>
									<span>Wichita, KS 67207</span>
								</p>
								<img
									className="logo-text"
									src="/images/logos/afwd-logo-text.svg"
									alt=" Aaron Fisher Web Development"
								/>
								<p className="contact">
									<span>316.573.9984</span>

									<span>
										<a className="email" href="#contact" onClick={openContact}>
											Contact
										</a>
									</span>
								</p>
							</div>
						</div>
					</div>
				</Container>
			</header>
		</HeaderComponent>
	);
};


/**
 *
 */
export default Header;