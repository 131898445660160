// noinspection HtmlUnknownAnchorTarget,JSUnresolvedVariable

import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import {Dropzone} from 'dropzone';

// styles
import {UploadPageComponent} from './styles';

// components
import PageSection from '../../components/PageSection';


/**
 *
 * @return {JSX.Element}
 * @constructor
 */
const UploadPage = () =>
{
	const initialFormFields                         = {
		name:    {
			value: '',
			ref:   useRef(),
		},
		email:   {
			value: '',
			ref:   useRef(),
		},
		message: {
			value: '',
			ref:   useRef(),
		}
	};
	const formActions                               = {
		'development': 'http://localhost:8008/actions/upload/submit/',
		'production':  '/actions/upload/submit/',
	};
	const uploadActions                             = {
		'development': 'http://localhost:8008/actions/upload/file/',
		'production':  '/actions/upload/file/',
	};
	const infoActions                               = {
		'development': 'http://localhost:8008/actions/upload/info/',
		'production':  '/actions/upload/info/',
	};
	const formAction                                = formActions[process.env.NODE_ENV];
	const uploadAction                              = uploadActions[process.env.NODE_ENV];
	const infoAction                                = infoActions[process.env.NODE_ENV];
	const [formFields, setFormFields]               = useState( initialFormFields );
	const [formSubmitting, setFormSubmitting]       = useState( false );
	const [formValidated, setFormValidated]         = useState( false );
	const [formComplete, setFormComplete]           = useState( false );
	const [formErrorMessages, setFormErrorMessages] = useState( [] );
	const [uploadedFiles, setUploadedFiles]         = useState( [] );
	const [filesUploading, setFilesUploading]       = useState( false );
	const [maxUploadSizeMb, setMaxUploadSizeMb]     = useState( 0 );
	const formRef                                   = useRef();
	const dropzoneRef                               = useRef();
	const fileListRef                               = useRef();

	/**
	 *
	 * @param {Event} e
	 */
	const handleInputChange = e =>
	{
		const value     = e.target.value;
		const name      = e.target.name;
		const newValues = { ...formFields };

		newValues[name].value = value;
		setFormFields( newValues );
	};

	/**
	 *
	 * @param {Event} e
	 */
	const submit = e =>
	{
		e.preventDefault();

		const form        = formRef.current;
		const formData    = new FormData( form );
		const fetchParams = {
			method: 'POST',
			body:   formData,
		};

		if (form instanceof HTMLFormElement) {

			if (form.checkValidity() !== false) {

				setFormSubmitting( true );

				fetch( formAction, fetchParams )
					.then( data => data.json() )
					.then( /** @param {FormResponse} response */response =>
					{
						setFormSubmitting( false );

						if (response.success) {

							setFormComplete( true );

						} else {

							setFormErrorMessages( response.errorMessages );

							response.fieldErrors.forEach( err =>
							{
								const field = formFields[err.name];

								if (field.ref.current instanceof HTMLElement) {
									field.ref.current.setCustomValidity( 'bad' );
								}
							} );
						}
					} )
					.catch( error =>
					{
						setFormSubmitting( false );
						console.error( 'Error:', error );
						alert( 'An unknown error occurred' );
					} );
			}

			setFormValidated( true );
		}
	};

	/**
	 *
	 */
	const initDropzone = () =>
	{
		const dropzoneElem = dropzoneRef.current;

		if (dropzoneElem && !dropzoneElem.dropzone) {

			try {

				Dropzone.autoDiscover = false;

				/**
				 * @see https://docs.dropzone.dev/configuration/basics/configuration-options
				 * @type {{}}
				 */
				const options  = {
					url:                uploadAction,
					maxFilesize:        maxUploadSizeMb * 100000,
					dictDefaultMessage: `
						Drop files to upload<br>
						or<br>
						<button type="button" class="btn btn-outline-secondary">Select Files</button><br>
						Maximum upload file size: ${maxUploadSizeMb} MB.
					`,
				};
				const dropzone = new Dropzone( dropzoneElem, options );

				dropzone.options.dictDefaultMessage = 'zxcv';

				dropzone.on( 'complete', file =>
				{
					const errors      = [];
					const xhrResponse = file?.xhr?.response;

					if (file.status === 'success') {

						if (xhrResponse) {
							try {

								const response = JSON.parse( xhrResponse );

								if (response.success) {

									response.fileNames.forEach( fileName =>
									{
										const newFile = {
											fileName:     fileName,
											originalName: file.name,
											size:         file.size,
										};

										setUploadedFiles( uploadedFiles => [...uploadedFiles, newFile] );
									} );

								} else {

									response.errorMessages.forEach( msg => errors.push( msg ) );
								}

							} catch (err) {

								errors.push( 'The file could not be uploaded due to an unknown error.' );
								console.log( err );
							}

						} else {

							errors.push( 'Upload failed' );
						}

					} else {

						errors.push( 'The file could not be uploaded. This could be due to a network error or the file may exceed the maximum size of ' + maxUploadSizeMb + 'MB' );
					}

					if (errors.length) {
						dropzone.removeFile( file );
						alert( errors.join( "\n" ) );
						console.log( file );
					}
				} );

				dropzone.on( 'addedfiles', () => setFilesUploading( true ) );
				dropzone.on( 'queuecomplete', () => setFilesUploading( false ) );

				dropzoneElem.dropzone = dropzone;

			} catch (error) {
			}
		}
	};

	useEffect( () =>
	{
		fetch( infoAction )
			.then( json => json.json() )
			.then( response =>
			{
				if (response.success) {
					setMaxUploadSizeMb( response.maxUploadSizeMb );
				}
			} );
	}, [infoAction] );

	useEffect( () =>
	{
		if (maxUploadSizeMb > 0) {
			initDropzone();
		}

	}, [maxUploadSizeMb] );


	return (
		<UploadPageComponent>

			<PageSection id="upload" heading="Upload Files">
				<Container>
					<Row>
						<Col>
							<Form
								action={formAction}
								ref={formRef}
								noValidate
								validated={formValidated}
								onSubmit={e => submit( e )}
							>

								{formComplete
								 ?
								 <>
									 <Alert variant="success">
										 <h3>Thank you</h3>
										 <p>
											 Your files have been sent.
										 </p>
									 </Alert>
								 </>
								 :
								 <>
									 {formErrorMessages.map( ( msg, key ) =>
										 <Alert variant="danger" key={key}>
											 <p>
												 {msg}
											 </p>
										 </Alert>
									 )}

									 <Row>
										 <Col md={6}>
											 <div
												 id="file-upload-dropzone"
												 className="dropzone"
												 ref={dropzoneRef}
											 />
										 </Col>
										 <Col md={6}>
											 <Form.Group className="mb-4" controlId="contactName">
												 <Form.Label className="required">Name</Form.Label>
												 <Form.Control
													 type="text"
													 name="name"
													 value={formFields.name.value}
													 ref={formFields.name.ref}
													 onChange={e => handleInputChange( e )}
													 required
												 />
												 <Form.Control.Feedback type="invalid">
													 Please enter your name.
												 </Form.Control.Feedback>
											 </Form.Group>

											 <Form.Group className="mb-4" controlId="contactEmail">
												 <Form.Label className="required">Email Address</Form.Label>
												 <Form.Control
													 type="email"
													 name="email"
													 value={formFields.email.value}
													 ref={formFields.email.ref}
													 onChange={e => handleInputChange( e )}
													 required
												 />
												 <Form.Control.Feedback type="invalid">
													 Please enter a valid email address.
												 </Form.Control.Feedback>
											 </Form.Group>

											 <Form.Group className="mb-4" controlId="contactMessage">
												 <Form.Label>Message</Form.Label>
												 <Form.Control
													 as="textarea"
													 rows="4"
													 name="message"
													 value={formFields.message.value}
													 ref={formFields.message.ref}
													 onChange={e => handleInputChange( e )}
												 />
												 <Form.Control.Feedback type="invalid">
													 Please enter your message.
												 </Form.Control.Feedback>
											 </Form.Group>
										 </Col>
									 </Row>

									 {/* todo - recaptcha */}
								 </>
								}

								{!formComplete &&
									<Button
										variant="outline-primary"
										size="lg"
										type="submit"
										disabled={formSubmitting || filesUploading}
									>
										{filesUploading
										 ?
										 <>
											 Uploading...
										 </>
										 :
										 <>
											 Send {uploadedFiles.length ? uploadedFiles.length : ''} File{uploadedFiles.length === 1 ? '' : 's'}
										 </>
										}

										{(formSubmitting || filesUploading) &&
											<div className="submitting">
												<Spinner animation="border" size="sm" />
											</div>
										}
									</Button>
								}

								<input type="hidden" name="fileList" ref={fileListRef}
									   value={JSON.stringify( uploadedFiles )} />

							</Form>
						</Col>
					</Row>
				</Container>
			</PageSection>

		</UploadPageComponent>
	);
};


/**
 *
 */
export default UploadPage;