import * as React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {RemoveScroll} from 'react-remove-scroll';

// styles
import {PortfolioCardComponent} from './styles';

// components
import Slideshow from '../../Slideshow';
import TagList from '../../TagList';
import ButtonLinkList from '../../ButtonLinkList';
import CloseButton from '../../CloseButton';


/**
 *
 * @param {{
 * 		portfolioItem: PortfolioItem,
 * 		modalState: string,
 * 		modalCloseDelay: number,
 * 		isForeground: boolean,
 * 		isModal: boolean,
 * 		setSelectedItem: function,
 * 		setModalState: function
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const PortfolioCard = ( {
							portfolioItem,
							modalState,
							modalCloseDelay,
							isForeground,
							isModal,
							setSelectedItem,
							setModalState
						} ) =>
{
	const cssClass     = [
		modalState,
		isForeground ? 'foreground' : '',
		isModal ? 'is-modal' : '',
	].join( ' ' );
	const close        = e =>
	{
		e.stopPropagation();
		setModalState( 'modal-closing' );
		setTimeout( () => setSelectedItem( null ), modalCloseDelay * 1000 );
	};
	const removeScroll = isModal && (modalState === 'modal-opened' || modalState === 'modal-closing');
	const inner        =
			  <>
				  <picture className="thumb">
					  <div className="bg" style={{ backgroundColor: portfolioItem.branding.color }}>
						  <img src={portfolioItem.branding.logoUrl} alt={portfolioItem.name} />
					  </div>
				  </picture>
				  <Container>
					  <Row>
						  <Col className="info-col" xs={{ order: 2 }} md={{ span: 5, order: 1 }}>
							  <div className="info">
								  <div className="d-none d-md-block">
									  <h3>
										  {portfolioItem.name}
									  </h3>
									  {portfolioItem.clients &&
										  <div className="client">
											  <p className="label">
												  Client:
											  </p>
											  <ul>
												  {portfolioItem.clients.map( ( client, key ) =>
												  {
													  if (client.url) {
														  return (
															  <li key={key}>
																  <a href={client.url} target="_blank"
																	 rel="noreferrer">
																	  {client.name}
																  </a>
															  </li>
														  );
													  }

													  return (
														  <li key={key}>
															  {client.name}
														  </li>
													  );
												  } )}
											  </ul>
										  </div>
									  }
								  </div>
								  <TagList tags={portfolioItem.tags} />
								  <div
									  className="copy"
									  dangerouslySetInnerHTML={{ __html: portfolioItem.copy }}
								  />
								  <ButtonLinkList links={portfolioItem.links} />
							  </div>
						  </Col>
						  <Col className="slideshow-col" xs={{ order: 1 }} md={{ span: 7, order: 2 }}>
							  <div className="info d-block d-md-none">
								  <h3>
									  {portfolioItem.name}
								  </h3>
								  {portfolioItem.clients &&
									  <div className="client">
										  <p className="label">
											  Client:
										  </p>
										  <ul>
											  {portfolioItem.clients.map( ( client, key ) =>
											  {
												  if (client.url) {
													  return (
														  <li key={key}>
															  <a href={client.url} target="_blank" rel="noreferrer">
																  {client.name}
															  </a>
														  </li>
													  );
												  }

												  return (
													  <li key={key}>
														  {client.name}
													  </li>
												  );
											  } )}
										  </ul>
									  </div>
								  }
							  </div>
							  <Slideshow images={portfolioItem.images} />
						  </Col>
					  </Row>
				  </Container>
			  </>;


	return (
		<PortfolioCardComponent className={cssClass}>
			<CloseButton onClick={e => close( e )} />
			{removeScroll
			 ?
			 <RemoveScroll className="scroll" removeScrollBar={false}>
				 {inner}
			 </RemoveScroll>
			 :
			 <div className="scroll">
				 {inner}
			 </div>
			}
		</PortfolioCardComponent>
	);
};


/**
 *
 */
export default PortfolioCard;