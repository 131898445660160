// styles
import {CloseButtonComponent} from './styles';


/**
 *
 * @param {{
 * 		onClick: function,
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const CloseButton = ( { onClick } ) =>
{
	return (
		<CloseButtonComponent>
			<button
				type="button"
				className="close-button"
				aria-label="Close"
				onClick={onClick}
			></button>
		</CloseButtonComponent>
	);
};


/**
 *
 */
export default CloseButton;