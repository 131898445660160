import styled, {css} from 'styled-components';


export const PortfolioCardComponent = styled.div`
	position: relative;
	background: ${( { theme } ) => theme.colors.darkGrey};
	transition: transform 0.15s cubic-bezier(.01, .72, .16, 1);
	width: 100%;
	height: 100%;
	z-index: 1;
	aspect-ratio: 1;
	overflow: hidden;

	.scroll {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		z-index: 1;
		padding: 2rem 0 0 0;
		overflow-x: hidden;
		overflow-y: hidden;

		${( { theme } ) => css`
			@media (${theme.mediaQueries.mdUp}) {
				padding: 0;
			}
		`};

		.thumb {
			position: absolute;
			width: 100%;
			height: 101%; // fix math/rounding bug in firefox which allows background to be visible in 0.0625rem line at bottom
			top: 0;
			left: 0;
			z-index: 3;
			perspective: 62.5rem;
			cursor: pointer;
			transform: scale(1);
			filter: blur(0);
			transition: opacity 0.75s, filter 0.5s;

			.bg {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				&:before {
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: url(/images/bgs/pixel-gradient-2-color.png) 50% 50% / cover;
					mix-blend-mode: darken;
					opacity: 0.05;
				}

				&:after {
					content: '';
					display: block;
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: rgba(0, 0, 0, 0.25);
					opacity: 1;
					transition: opacity 0.25s;
				}

				img {
					position: relative;
					width: 66.67%;
					z-index: 3;
					transform: scale(1);
					transition: transform 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
					//background: 50% 50% / cover no-repeat;
					//filter: blur(0.3125rem);
				}

				&:hover {
					&:after {
						opacity: 0;
					}

					img {
						transform: scale(1.1);
					}
				}
			}
		}

		> .container {
			height: 100%;
			max-height: 100%;

			${( { theme } ) => css`
				@media (${theme.mediaQueries.smDown}) {
					padding-left: 1rem;
					padding-right: 1rem;
				}
			`};


			> .row {
				height: 100%;
				max-height: 100%;

				> .info-col,
				> .slideshow-col {
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding-bottom: 1rem;

					${( { theme } ) => css`
						@media (${theme.mediaQueries.mdUp}) {
							height: 100%;
							max-height: 100%;
							padding-bottom: 0;
						}
					`};
				}
			}
		}
	}

	.close-button {
		display: none;
	}

	.info {
		max-height: 100%;
		padding: 1.5rem 0 0 0;

		${( { theme } ) => css`
			@media (${theme.mediaQueries.mdUp}) {
				padding: 0;
			}
		`};

		h3 {
			font-size: calc(100% + 1vw);
		}

		.client {
			display: flex;
			margin: 0 0 1rem 0;
			font-size: 0.875rem;

			.label {
				margin: 0 0.5rem 0 0;
				text-transform: uppercase;
			}

			ul {
				line-height: 2;
				display: flex;
				flex-wrap: wrap;

				li {
					&:not(:last-child):after {
						content: '/';
						display: inline-block;
						margin: 0 0.25rem;
						opacity: 0.25;
					}
				}
			}
		}

		.tags {
		}

		.copy {
			font-size: 0.875rem;
		}
	}


	&.is-modal {

		.scroll {
			overflow-y: auto;
		}

		&.modal-opening,
		&.modal-opened {

			.scroll {
				aspect-ratio: auto;
			}

			.thumb {
				opacity: 0;
				filter: blur(0.9375rem);
			}

			.close-button {
				display: block;
				position: absolute;
				top: 1rem;
				right: 1.5rem;
				z-index: 10;
			}
		}

		&.modal-opened {
			.thumb {
				visibility: hidden;
			}
		}

		&.modal-closing {
			.thumb {
				filter: blur(0.9375rem);
				opacity: 1;
			}
		}
	}

	&.modal-opened.foreground:not(.is-modal) {
		opacity: 0;
	}

	${( { theme } ) => css`
		@media (${theme.mediaQueries.mdUp}) {
			&:not(.is-modal):not(.modal-closing):hover {
				transform: scale(1.1);
			}
		}
	`};

`;
