import styled from 'styled-components';


export const PictureComponent = styled.div`

	figure {
		display: block;
		position: relative;

		picture {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
			}
		}

		figcaption {
			padding: 1rem 0;
			font-size: 0.875rem;
		}
	}

`;
