import styled from 'styled-components';


export const PortfolioCardModalComponent = styled.div`

	z-index: 2;
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	padding: 4rem 1.5rem;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.25s ease;

	&.modal-opening,
	&.modal-opened {
		z-index: 100;
		background-color: ${( { theme } ) => theme.colors.modalBlack};
	}

	.item-wrapper {
		width: 100%;
		height: 100%;
		max-width: 82.5rem;
		margin: 0 auto;
	}
`;
