import {useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {Button, Spinner} from 'react-bootstrap';

// components
import Picture from '../Picture';

// styles
import {SlideshowComponent} from './styles';


/**
 *
 * @return {JSX.Element}
 * @constructor
 */
const Slideshow = ( { images } ) =>
{
	const [page, setPage]           = useState( 0 );
	const [direction, setDirection] = useState( 1 );
	const imageKey                  = Math.abs( page % images.length );
	const currentImage              = images.find( ( image, key ) => key === imageKey );
	const hasMultipleImages         = images.length > 1;
	const dots                      = [];
	const showLarge                 = image =>
	{
		window.open( image.sizes.xs );
	};
	const choosePage                = pageIndex =>
	{
		let newPage = pageIndex;

		if (newPage < 0) {
			newPage = images.length - 1;
		} else if (newPage > images.length - 1) {
			newPage = 0;
		}

		setPage( newPage );
		setDirection( newPage > page ? 1 : -1 );
	};

	for (let i = 0; i < images.length; i++) {
		dots.push( {
			index:    i,
			num:      i + 1,
			selected: page === i
		} );
	}


	if (!currentImage) {
		return <></>;
	}


	return (
		<SlideshowComponent>
			<AnimatePresence initial={false} custom={direction}>
				<motion.div
					className="image"
					custom={direction}
					key={page}
					initial="enter"
					animate="center"
					exit="exit"
					variants={{
						enter:  direction => ({
							x: direction > 0 ? '100%' : '-100%',
						}),
						center: {
							x: 0,
						},
						exit:   direction => ({
							x: direction < 0 ? '100%' : '-100%',
						}),
					}}
					transition={{
						type:      "spring",
						stiffness: 300,
						damping:   25,
						//duration:  100.25
					}}
				>
					<Picture
						image={currentImage}
						onClick={() => showLarge( currentImage )}
					/>
				</motion.div>
			</AnimatePresence>
			{hasMultipleImages &&
				<>
					<div className="controls bottom">
						<Button
							className="prev"
							variant="link"
							onClick={() => choosePage( page - 1 )}
						>
							Previous
						</Button>
						<Button
							className="next"
							variant="link"
							onClick={() => choosePage( page + 1 )}
						>
							Next
						</Button>
					</div>
					<ul className="dots">
						{dots.map( ( dot, key ) =>
							<li key={key}>
								<button
									className={dot.selected ? 'selected' : ''}
									onClick={() => choosePage( dot.index )}
								>
									{dot.num}
								</button>
							</li>
						)}
					</ul>
				</>
			}
			<div className="loading">
				<Spinner animation="border" />
			</div>
		</SlideshowComponent>
	);
};


/**
 *
 */
export default Slideshow;