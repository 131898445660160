import styled, {css} from 'styled-components';


export const FooterComponent = styled.div`

	footer {
		padding: 3rem 0;
		z-index: 10;
		position: relative;
		display: flex;

		.container {
			padding: 1.5rem 0;
		}

		.inner {
			display: flex;
			justify-content: center;
			align-items: center;
			opacity: 0.5;

			&:before,
			&:after {
				content: '';
				display: block;
				width: 1.5rem;
				height: 2.5rem;
				background: url(/images/icons/pixel-bracket-white.svg) 50% 50% / 100% auto no-repeat;
				opacity: 0.5;
				mix-blend-mode: color-dodge;
			}

			&:after {
				transform: rotateZ(180deg);
			}

			.content {
				position: relative;
				font-size: 0.75rem;
				margin: 0 1rem;
				color: ${( { theme } ) => theme.colors.lightGrey};
				text-transform: uppercase;
				text-align: center;
				font-family: ${( { theme } ) => theme.typography.fonts.pixel};

				${( { theme } ) => css`
					@media (${theme.mediaQueries.mdUp}) {
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 0 2rem;
					}
				`};

				.copyright {
					margin: 0;
				}

				.links {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 2rem;
					width: 100%;

					${( { theme } ) => css`
						@media (${theme.mediaQueries.mdUp}) {
							position: relative;
							top: 0;
							width: auto;
						}
					`};

					&:before {
						${( { theme } ) => css`
							@media (${theme.mediaQueries.mdUp}) {
								content: '';
								display: block;
								width: 0.125rem;
								height: 0.875rem;
								background: ${( { theme } ) => theme.colors.lightGrey};
								margin: 0 1rem;
								position: relative;
								top: -0.0625rem;
							}
						}
						`};
					}

					li {
						&:not(:last-child) {

							&:after {
								content: '■';
								position: relative;
								top: -0.0625rem;
								margin: 0 0.75rem;
								opacity: 0.5;
								font-size: 0.5625rem;
							}
						}
					}

					a {
						color: ${( { theme } ) => theme.colors.white};
					}
				}
			}
		}
	}

`;