import styled, {css} from 'styled-components';


export const ContactModalComponent = styled.div`

	backdrop-filter: blur(0.25rem);

	.modal-header {
		.modal-title {
			text-transform: uppercase;
			font-size: 1.5rem;
			color: #fff;
			font-family: '04b03regular', monospace;
			text-shadow: 0.125rem 0.125rem 0 #001a1a, 0.25rem 0.25rem 0 #001a1a;
		}
	}

	.modal-body {
		padding-bottom: 0;

		.address {
			font-size: 0.625rem;
			position: relative;
			top: -2.5rem;
			color: ${( { theme } ) => theme.colors.transparentWhite};
			white-space: nowrap;

			p {
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0;

				span {

					&:not(:last-child):after {
						content: '■';
						position: relative;
						top: -0.0625rem;
						margin: 0 0.75rem;
						opacity: 0.5;
						font-size: 0.5625rem;
					}
				}
			}
		}
	}

	.modal-footer {
		.submitting {
			margin: 0 0 0 0.75rem;
			display: inline-block;
		}
	}


	${( { theme } ) => css`
		@media (${theme.mediaQueries.mdUp}) {

		}
	`};

`;