import React, {useEffect} from 'react';
import {ThemeProvider} from 'styled-components';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import AOS from 'aos';


// styles
import {GlobalStyle} from '../styles/globalStyle';
import {theme} from '../styles/theme';
import '../styles/bootstrap-custom.scss';
import '../styles/aos-custom.scss';
import '../styles/dropzone-custom.scss';

// components
import Page from './components/Page';

// pages
import HomePage from './pages/Home/index';
import UploadPage from './pages/Upload';


// todo - remove
//import ScreenInfo from './components/Dev/ScreenInfo';
//import MobileCheck from './components/Dev/MobileCheck';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () =>
{
	const router = createBrowserRouter( [
		{
			path:    "/",
			element: <Page page={HomePage} />,
		},
		{
			path:    "/upload",
			element: <Page page={UploadPage} />,
		},
	] );

	useEffect( () =>
	{
		// noinspection JSUnresolvedFunction
		AOS.init( {
			offset: 20,
		} );
	}, [] );

	return (

		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<RouterProvider router={router} />
			{/*
			<ScreenInfo />
			<MobileCheck />
			*/}
		</ThemeProvider>
	);
};


/**
 *
 */
export default App;
