import React, {useRef, useEffect} from "react";
import Rellax from "rellax";

// styles
import {SiteBgComponent} from './styles';


/**
 *
 * @return {JSX.Element}
 * @constructor
 */
const SiteBg = () =>
{
	const parallaxElements = [
		{
			className: 'grid-light green small',
			position:  { left: '5%', top: '80px' },
			speed:     -12,
			ref:       useRef(),
		},
		{
			className: 'grid-light green medium',
			position:  { left: '-5%', top: '50%' },
			speed:     -35,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue medium',
			position:  { left: '40%', top: '10%' },
			speed:     -24,
			ref:       useRef(),
		},
		{
			className: 'grid-light green large',
			position:  { left: '60%', top: '-80%' },
			speed:     -30,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue large',
			position:  { left: '0%', top: '-120%' },
			speed:     -35,
			ref:       useRef(),
		},
		// #####################################
		{
			className: 'grid-light green medium',
			position:  { left: '50%', top: '-280%' },
			speed:     -40,
			ref:       useRef(),
		},
		{
			className: 'grid-light green medium',
			position:  { left: '50%', top: '-200%' },
			speed:     -20,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue medium',
			position:  { left: '10%', top: '-220%' },
			speed:     -25,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue medium',
			position:  { left: '30%', top: '-500%' },
			speed:     -31,
			ref:       useRef(),
		},
		{
			className: 'grid-light green large',
			position:  { left: '30%', top: '-450%' },
			speed:     -26,
			ref:       useRef(),
		},
		// #####################################
		{
			className: 'grid-light green small',
			position:  { left: '10%', top: '-550%' },
			speed:     -32,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue medium',
			position:  { left: '5%', top: '-550%' },
			speed:     -25,
			ref:       useRef(),
		},
		{
			className: 'grid-light green large',
			position:  { left: '75%', top: '-550%' },
			speed:     -40,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue medium',
			position:  { left: '25%', top: '-650%' },
			speed:     -25,
			ref:       useRef(),
		},
		{
			className: 'grid-light green large',
			position:  { left: '-5%', top: '-550%' },
			speed:     -20,
			ref:       useRef(),
		},
		// #####################################
		{
			className: 'grid-light green small',
			position:  { left: '70%', top: '-920%' },
			speed:     -33,
			ref:       useRef(),
		},
		{
			className: 'grid-light blue small',
			position:  { left: '70%', top: '-7300px' },
			speed:     -26,
			ref:       useRef(),
		},
	];


	useEffect( () =>
	{
		parallaxElements.forEach( elem =>
		{
			new Rellax( elem.ref.current, {
				speed:      elem.speed,
				center:     false,
				wrapper:    null,
				round:      true,
				vertical:   true,
				horizontal: false
			} );
		} );
	} );


	return (
		<SiteBgComponent>
			{parallaxElements.map( ( elem, key ) =>
				<div
					data-num={key + 1}
					className={elem.className}
					style={{
						left: elem.position.left,
						top:  elem.position.top
					}}
					ref={elem.ref}
					key={key}
				/>
			)}
		</SiteBgComponent>
	);
};


export default SiteBg;