import styled from 'styled-components';


export const PortfolioCardGroupComponent = styled.div`

	.portfolio-card-wrapper {
		position: relative;

		&.foreground {
			z-index: 2;
		}
	}
`;