import styled, {css} from 'styled-components';


export const SkillListComponent = styled.div`

	.card {
		background-color: ${( { theme } ) => theme.colors.modalBlack};
		border: none;
		overflow: hidden;

		${( { theme } ) => css`
			@media (${theme.mediaQueries.mdUp}) {
				aspect-ratio: 1.33;
			}
		`};

		${( { theme } ) => css`
			@media (${theme.mediaQueries.lgUp}) {
				aspect-ratio: 1.5;

				&.tall {
					aspect-ratio: 1;
				}
			}
		`};

		${( { theme } ) => css`
			@media (${theme.mediaQueries.xlUp}) {
				aspect-ratio: 1.5;

				&.tall {
					aspect-ratio: 1.5;
				}
			}
		`};

		.card-header {
			position: relative;
			font-size: 1.375rem;
			border: none;
			font-family: ${( { theme } ) => theme.typography.fonts.pixel};
			text-transform: uppercase;
			padding: 1rem 1.5rem 0.5rem;

			&:before {
				content: '';
				display: inline-block;
				width: 0.75rem;
				height: 1.25rem;
				padding: 0;
				background: url('/images/icons/pixel-arrow-green.svg') 50% 50% / 100% auto no-repeat;
				margin: 0 0.75rem 0 0;
				position: relative;
				top: 0.1875rem;
			}
		}

		.card-body {
			padding: 1rem 1.5rem;

			.tags {
				margin: 0;
			}
		}
	}
`;