import {PictureComponent} from './styles';


/**
 *
 * @param {{
 * 		image: Image,
 * 		objectFit: ('cover'|'contain'|'fill'|'none'|'scale-down'|'inherit'|'initial'|'revert'|'revert-layer'|'unset'),
 * 		onClick: function|undefined
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const Picture = ( { image, objectFit = 'cover', onClick } ) =>
{
	const breakpointImages = getBreakpointImages( image );

	return (
		<PictureComponent data-picture-component>
			<figure>
				<picture style={{ objectFit: objectFit }} onClick={onClick}>
					{breakpointImages.map( item =>
						<img src={item.img.src} alt={item.img.alt} className={item.className} key={item.key} />
					)}
				</picture>
				{image.caption &&
					<figcaption>
						{image.caption}
					</figcaption>
				}
			</figure>
		</PictureComponent>
	);
};


/**
 *
 * @param image
 * @return {{img: {src: *, alt: *, caption: *}, className: *, key: *}[]}
 */
const getBreakpointImages = image =>
{
	const breakpoints = {
		xs:  {
			className: 'd-block d-sm-none',
		},
		sm:  {
			className: 'd-none d-sm-block d-md-none',
		},
		md:  {
			className: 'd-none d-md-block d-lg-none',
		},
		lg:  {
			className: 'd-none d-lg-block d-xl-none',
		},
		xl:  {
			className: 'd-none d-xl-block d-xxl-none',
		},
		xxl: {
			className: 'd-none d-xxl-block',
		},
	};
	let lastImageSrc  = null;

	Object.keys( breakpoints ).forEach( size =>
	{
		if (!lastImageSrc && image.sizes[size]) {
			lastImageSrc = image.sizes[size];
		}
	} );

	return Object.keys( breakpoints ).map( size =>
	{
		if (image.sizes[size]) {
			lastImageSrc = image.sizes[size];
		}

		return {
			className: breakpoints[size].className,
			key:       size,
			img:       {
				src:     lastImageSrc,
				alt:     image.alt,
				caption: image.caption,
			}
		};
	} );
};


/**
 *
 */
export default Picture;