import {useState} from 'react';
import {motion} from 'framer-motion';
import {Container, Row, Col} from 'react-bootstrap';

// app components
import PortfolioCard from '../Card';
import PortfolioCardModal from '../CardModal';

// styles
import {PortfolioCardGroupComponent} from './styles';


/**
 *
 * @param {{
 * 		portfolioItems: PortfolioItem[],
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const PortfolioCardGroup = ( { portfolioItems } ) =>
{
	const [selectedItem, setSelectedItem]     = useState( null );
	const [foregroundItem, setForegroundItem] = useState( null );
	const [modalState, setModalState]         = useState( 'modal-closed' );
	const modalCloseDelay                     = 0.25;


	return (
		<PortfolioCardGroupComponent>
			<Container>
				<Row>
					{portfolioItems.map( ( item, key ) =>
						<Col
							className="mb-5"
							key={key}
							xs={6}
							md={4}
							lg={3}
							xl={3}
						>
							<motion.div
								className={`portfolio-card-wrapper ${foregroundItem?.handle === item.handle ? 'foreground' : ''}`}
								layout
								initial={false}
								layoutId={item.layoutId}
								onClick={() => setSelectedItem( item )}
								transition={{ delay: 0, duration: 0.4 }}
								animate
								onLayoutAnimationStart={() => setForegroundItem( item )}
								onLayoutAnimationComplete={() => setModalState( 'modal-closed' )}
							>
								<PortfolioCard
									portfolioItem={item}
									modalState={modalState}
									isForeground={foregroundItem && foregroundItem?.handle === item.handle}
									setSelectedItem={setSelectedItem}
									modalCloseDelay={modalCloseDelay}
								/>
							</motion.div>
						</Col>
					)}
				</Row>
			</Container>
			<PortfolioCardModal
				portfolioItem={selectedItem}
				modalState={modalState}
				setSelectedItem={setSelectedItem}
				setForegroundItem={setForegroundItem}
				setModalState={setModalState}
				modalCloseDelay={modalCloseDelay}
			/>
		</PortfolioCardGroupComponent>
	);
};


/**
 *
 */
export default PortfolioCardGroup;