import styled, {css} from 'styled-components';


export const ButtonLinkListComponent = styled.div`

	.links {
		display: flex;
		flex-wrap: wrap;
		column-gap: 1rem;

		li {
			margin: 0 0 1rem 0;
		}

		a {

			&:after {
				content: '';
				display: inline-block;
				width: 0.8125rem;
				height: 1.25rem;
				background: url('/images/icons/pixel-arrow-green.svg') 50% 50% / auto 100% no-repeat;
				transform: rotateZ(180deg);
				position: relative;
				left: 0.3125rem;
				top: 0.25rem;
				padding: 0 2rem 0 0;

				${( { theme } ) => css`
					@media (${theme.mediaQueries.smDown}) {
						width: 0;
						height: 0.9375rem;
					}
				`};
			}

			&:hover:after {
				background-image: url('/images/icons/pixel-arrow-black.svg');
			}
		}
	}

`;
