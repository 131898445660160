import styled, {css} from 'styled-components';


export const SlideshowComponent = styled.div`

	position: relative;
	overflow-x: clip;
	margin: 0;
	height: 0;
	padding-bottom: calc(62.5% + 7.625rem);

	.image {
		position: absolute;
		z-index: 1;

		figure {
			margin: 0 0 4rem 0;

			${( { theme } ) => css`
				@media (${theme.mediaQueries.mdUp}) {
					margin: 0;
				}
			`};

			picture {
				aspect-ratio: 1.6;
				width: auto;
				height: auto;
				cursor: zoom-in;
			}

			figcaption {
				position: relative;
				margin: 4rem 0 0 0;
				padding: 0;
				text-align: center;
				height: 2.625rem;
			}
		}
	}

	.controls {
		.next,
		.prev {
			top: calc(50% - 1.25rem);
			position: absolute;
			display: flex;
			justify-content: center;
			align-items: center;
			user-select: none;
			cursor: pointer;
			z-index: 2;
			width: 0.8125rem;
			height: 1.25rem;
			padding: 0;
			background: url('/images/icons/pixel-arrow-green.svg') 50% 50% / 100% auto no-repeat;
			text-indent: 625rem;
			white-space: nowrap;
			overflow: hidden;
			transition: transform 0.2s ease;

			&:hover {
				transform: scale(1.25);
			}
		}

		.next {
			right: 0.625rem;
			transform: rotateZ(180deg);

			&:hover {
				transform: rotateZ(180deg) scale(1.25);
			}
		}

		.prev {
			left: 0.625rem;
		}

		&.bottom {
			.next,
			.prev {
				top: calc(100% - 6.25rem);
			}

			.next {
				right: 0;
			}

			.prev {
				left: 0;
			}
		}
	}

	.dots {
		position: absolute;
		top: calc(100% - 6.25rem);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		z-index: 1;
		padding: 0;


		li {
			margin: 0 0.5rem;

			${( { theme } ) => css`
				@media (${theme.mediaQueries.smUp}) {
					margin: 0 1rem;
				}
			`};

			button {
				display: block;
				cursor: pointer;
				border: none;
				width: 1.375rem;
				height: 1.25rem;
				background: url('/images/icons/pixel-box-green.svg') 50% 50% / 100% auto no-repeat;
				text-indent: 625rem;
				white-space: nowrap;
				overflow: hidden;
				padding: 0.625rem 0;
				transition: transform 0.2s ease;

				&.selected {
					background-image: url('/images/icons/pixel-box-grey.svg');
				}

				&:hover {
					transform: scale(1.25);
				}
			}
		}
	}

	.loading {
		display: flex;
		width: 100%;
		aspect-ratio: 1.6;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		opacity: 0.25;
	}
`;
