import {PageSectionComponent} from './styles';


/**
 *
 * @param {{
 * 		heading: string,
 * 		id: string,
 * 		bg: ('dark')|undefined,
 * 		children: 	JSX.Element
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const PageSection = ( { heading, id, bg, children } ) =>
{
	return (
		<PageSectionComponent>
			<section id={id} className={bg}>
				{heading &&
					<header>
						<h2>
							<span
								className="left"
								data-aos="blur-zoom-in-slide-left"
								data-aos-duration="1000"
							>
								<span className="bracket" />
							</span>
							<span
								className="heading"
								data-aos="blur-zoom-in"
								data-aos-duration="750"
								data-aos-delay="100"
							>
								{heading}
							</span>
							<span
								className="right"
								data-aos="blur-zoom-in-slide-right"
								data-aos-duration="1000"
							>
								<span className="bracket" />
							</span>
						</h2>
					</header>
				}
				{children}
			</section>
		</PageSectionComponent>
	);
};


/**
 *
 */
export default PageSection;