import styled, {css} from 'styled-components';


export const UploadPageComponent = styled.div`

	#upload {
		padding-top: 4rem;
	}
	
	#file-upload-dropzone {
		margin: 0 0 2rem 0;

		${( { theme } ) => css`
			@media (${theme.mediaQueries.mdUp}) {
				margin: 8px 0 0 0;
				height: calc(100% - 32px);
			}
		`};
	}

	[type="submit"] {
		display: block;
		width: 100%;
		max-width: 500px;
		margin: 2rem auto 0;
	}

	.alert-success {
		max-width: 500px;
		margin: 0 auto;
	}

	.submitting {
		margin: 0 0 0 0.75rem;
		display: inline-block;
	}

`;