import {Link} from 'react-router-dom';
import {Container} from 'react-bootstrap';

// styles
import {FooterComponent} from './styles';


/**
 *
 * @param {{
 * 		openContactModal: function,
 * 		closeContactModal: function,
 * 	}} props
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ( { openContactModal, closeContactModal } ) =>
{
	const year = new Date().getFullYear();

	return (
		<FooterComponent>
			<footer>
				<Container>
					<div className="inner">
						<div className="content">
							<p className="copyright">
								© {year} Aaron Fisher Web Development, LLC.
							</p>
							<ul className="links">
								<li>
									<Link to="/upload" relative="path">
										Upload Files
									</Link>
								</li>
								<li>
									<a href="#contact" onClick={e => openContactModal( e )}>
										Contact
									</a>
								</li>
							</ul>
						</div>
					</div>
				</Container>
			</footer>
		</FooterComponent>
	);
};


/**
 *
 */
export default Footer;

