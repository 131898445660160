import styled from 'styled-components';


export const HamburgerComponent = styled.div`
	position: relative;
	right: -0.5rem;
	z-index: 4;
	margin: 0 0 0 auto;
	font-size: 0;

	button {
		border: none;
		padding: 0.5rem;
		background: transparent;

		.grid {
			position: relative;
			width: 1.75rem;
			height: 1.75rem;
			gap: 0.125rem;
			display: flex;
			flex-wrap: wrap;
			transform: rotateZ(0deg);
			transition: transform 0.35s cubic-bezier(0.34, 1.56, 0.64, 1);
			opacity: 0.5;
			mix-blend-mode: color-dodge;

			.cell {
				width: 0.25rem;
				height: 0.25rem;
				background: #fff;
				transform: translate(0, 0);
				transition: transform 0.2s ease;

				&.row-1,
				&.row-3 {
					visibility: hidden;
				}
			}
		}

		&.open {

			.grid {
				transform: rotateZ(90deg);

				.cell {
					&.n-1,
					&.n-3 {
						transform: translate(0, 0.375rem);
					}

					&.n-21,
					&.n-23 {
						transform: translate(0, -0.375rem);
					}

					&.n-2 {
						transform: translate(0, 0.75rem);
					}

					&.n-22 {
						transform: translate(0, -0.75rem);
					}

					&.n-10 {
						transform: translate(12px, 0);
					}

					&.n-14 {
						transform: translate(-12px, 0);
					}

					&.n-11 {
						transform: translate(6px, 0);
					}

					&.n-13 {
						transform: translate(-6px, 0);
					}
				}
			}
		}
	}
`;