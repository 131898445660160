import styled, {css} from 'styled-components';


export const HomePageComponent = styled.div`

	position: relative;

	#intro {
		padding: 0;

		.blurb {
			position: relative;
			max-width: 53.875rem;
			height: calc(100vh - 8rem);
			min-height: 37.5rem;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			row-gap: 2rem;
			padding: 0 0 6rem 0;

			${( { theme } ) => css`
				@media (${theme.mediaQueries.mdUp}) {
					row-gap: 6rem;
					padding: 0;
				}
			`};

			.copy {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;

				& > * {
					position: relative;
				}

				&:before,
				&:after {
					content: '';
					position: relative;
					left: -1.25rem;
					display: block;
					height: 7.75rem;
					aspect-ratio: 0.6;
					background: url(/images/icons/pixel-bracket-white.svg) 50% 50% / 100% auto no-repeat;
					opacity: 0.1;
					mix-blend-mode: color-dodge;

					${( { theme } ) => css`
						@media (${theme.mediaQueries.mdUp}) {
							height: 11.25rem;
							left: 0;
							margin: 0 1.875rem 0 0;
						}
					`};
				}

				&:after {
					transform: rotateZ(180deg);
					right: -1.25rem;
					left: auto;

					${( { theme } ) => css`
						@media (${theme.mediaQueries.mdUp}) {
							right: 0;
							margin: 0 0 0 1.875rem;
						}
					`};
				}

				h1 {
					font-size: 1.25rem;
					line-height: 1.5;
					text-align: center;
					font-weight: 400;
					color: ${( { theme } ) => theme.colors.white};
					min-width: 13.75rem;

					${( { theme } ) => css`
						@media (${theme.mediaQueries.mdUp}) {
							font-size: 1.5rem;
						}
					`};

					strong {
						color: ${( { theme } ) => theme.colors.blue};
						font-weight: 500;
					}
				}
			}

			.more {

				a {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;

					.arrow {
						animation: droplet 4s infinite ease-out;
						animation-delay: 0.4s;

						span {
							display: block;
							width: 0.625rem;
							height: 1rem;
							background: url('/images/icons/pixel-arrow-green.svg') 50% 50% / 100% auto no-repeat;
							transform: rotateZ(-90deg);
						}

						&.n-1 {
							animation-delay: 0.2s;

							span {
								width: 1rem;
								height: 1.625rem;
							}
						}

						&.n-2 {
							animation-delay: 0s;

							span {
								width: 1.625rem;
								height: 2.8125rem;
							}
						}
					}
				}

				@keyframes droplet {
					0% {
						opacity: 0;
						transform: scale(.7) translate3d(0px, -70px, 0);
					}
					35% {
						opacity: 1;
						transform: scale(1) translate3d(0, 0, 0);
					}
					90% {
						opacity: 1;
					}
					100% {
						opacity: 0;
					}
				}
			}

			.keyboard {
				position: relative;
				display: block;
				width: 200%;
				aspect-ratio: 3.446428;
				background: url(/images/bgs/keyboard.svg) 50% 50% / auto 100% no-repeat;
				mix-blend-mode: color-dodge;
				opacity: 0.5;
				transform: perspective(38.75rem) rotate3d(1, 0, 0, 45deg);

				${( { theme } ) => css`
					@media (${theme.mediaQueries.mdUp}) {
						width: 100%;
					}
				`};
			}
		}
	}

	#portfolio {

	}

	#about {

	}

	#philosophy {
		strong {
			font-size: 1.5rem;
			margin: 0 0.25rem 0 0;
			color: ${( { theme } ) => theme.colors.blue};
		}
	}
`;