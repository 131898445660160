// styles
import {TagListComponent} from './styles';


/**
 *
 * @param {{
 * 		tags: string[],
 * 		animate: boolean
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const TagList = ( { tags, animate = false } ) =>
{
	const aosParams = animate ? { 'data-aos': 'blur-slide-up' } : {};

	return (
		<TagListComponent>
			{tags &&
				<ul className="tags">
					{tags.map( ( tag, key ) =>
						<li
							key={key}
							{...aosParams}
							data-aos-duration={500}
							data-aos-delay={key * 100}
						>
							{tag}
						</li>
					)}
				</ul>
			}
		</TagListComponent>
	);
};


/**
 *
 */
export default TagList;