import styled, {css} from 'styled-components';


export const HeaderComponent = styled.div`

	header {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 10;

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			backdrop-filter: blur(0.625rem);
			background: rgba(0, 27, 27, 0.75);
			z-index: -1;

			${( { theme } ) => css`
				@media (${theme.mediaQueries.mdUp}) {
					height: calc(100% + 3.75rem);
					mask: linear-gradient(180deg, #000 67%, rgba(0, 0, 0, 0) 100%);
				}
			`};
		}

		.container {
			padding-left: 1.5rem;
			padding-right: 1.5rem;
		}

		.sm-menu {
			position: relative;
			z-index: 10;
			padding: 0.5rem 0;
			display: flex;
			align-items: center;

			.logo {
				width: 10.75rem;
			}

			.hamburger {
				width: 3.125rem;
				height: 3.125rem;
				background: url(/images/icons/pixel-hamburger-white.svg) 50% 50% / auto 50% no-repeat;
				border: none;
				padding: 0.5rem;
				margin: 0 0 0 auto;
				position: relative;
				right: -0.5rem;
				z-index: 2;
			}

			.primary {
				position: absolute;
				z-index: 3;
				top: calc(100% - 4.25rem);
				right: -1rem;
				width: 11.25rem;
				padding: 3.5rem 0 0 0;
				animation: circle-wipe-out 0.5s ease;
				animation-fill-mode: forwards;

				${( { theme } ) => css`
					@media (${theme.mediaQueries.smUp}) {
						right: calc(50% - 16.5rem);
					}
				`};

				&:not(.initialized) {
					opacity: 0;
				}

				.box {
					position: relative;
					border: 0.3125rem solid rgba(255, 255, 255, 0.25);
					backdrop-filter: blur(0.3125rem); // todo - not working on firefox or chrome
					background: rgba(0, 27, 27, 0);
					padding: 1rem 0;
					transition: background 0.2s;
					transition-delay: 0s;

					&:before {
						content: '';
						display: block;
						position: absolute;
						right: -0.3125rem;
						top: -3.1875rem;
						width: 3.875rem;
						height: 3.1875rem;
						border: 0.3125rem solid rgba(255, 255, 255, 0.25);
						border-bottom: none;
						background: ${( { theme } ) => theme.colors.darkAqua};
					}

					ul {
						li {
							margin: 0.375rem 0;
							text-align: right;

							a {
								position: relative;
								display: block;
								padding: 1rem 1rem;
								line-height: 0;
								font-family: ${( { theme } ) => theme.typography.fonts.pixel};
								color: #fff;
								text-transform: uppercase;
								font-size: 1.25rem;
								top: 0.0625rem;
								transform: scale(1.25) translate(130px, 0);
								transition: transform 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);

								&:hover {
									text-decoration: none;
									color: ${( { theme } ) => theme.colors.green};
								}

								&.n-0 {
									transition-delay: 0s;
								}

								&.n-1 {
									transition-delay: 0.1s;
								}

								&.n-2 {
									transition-delay: 0.2s;
								}

								&.n-3 {
									transition-delay: 0.3s;
								}

								&.n-4 {
									transition-delay: 0.4s;
								}
							}
						}
					}
				}
			}

			&.menu-open {
				.hamburger {
					background-image: url(/images/icons/pixel-x-white.svg);
				}

				.primary {
					animation-name: circle-wipe-in;

					.box {
						background: rgba(0, 27, 27, 0.75);
						transition-delay: 0.4s;
					}

					ul li a {
						transform: scale(1) translate(0, 0);

						&.n-0 {
							transition-delay: 0.3s;
						}

						&.n-1 {
							transition-delay: 0.2s;
						}

						&.n-2 {
							transition-delay: 0.1s;
						}

						&.n-4 {
							transition-delay: 0s;
						}
					}
				}
			}
		}

		.lg-menu {
			padding: 1rem 0 0 0;

			.primary {
				ul {
					display: flex;
					justify-content: center;
					align-items: center;

					li {
						padding: 0 1rem;
						border: 0.3125rem solid rgba(255, 255, 255, 0.25);
						border-left: none;
						border-right: none;

						a {
							position: relative;
							display: block;
							padding: 1rem 1rem;
							line-height: 0;
							font-family: ${( { theme } ) => theme.typography.fonts.pixel};
							color: #fff;
							text-transform: uppercase;
							font-size: 1.25rem;
							top: 0.0625rem;

							&:before,
							&:after {
								content: '';
								display: block;
								position: absolute;
								right: 1.4375rem;
								top: 0.375rem;
								width: 0.5625rem;
								height: 1rem;
								padding: 0;
								background: url('/images/icons/pixel-arrow-green.svg') 50% 50% / 100% auto no-repeat;
								transition: all 0.25s ease;
								opacity: 0;
							}

							&:before {
								right: auto;
								left: 1.125rem;
								transform: rotateZ(180deg);
							}

							&:hover {
								text-decoration: none;
								color: ${( { theme } ) => theme.colors.white};

								&:before {
									left: -0.375rem;
									opacity: 1;
								}

								&:after {
									right: -0.125rem;
									opacity: 1;
								}
							}
						}

						&.logo {
							padding: 0 1rem;
							border-top: none;
							border-bottom: none;

							a {
								width: 3.75rem;
								height: 3.125rem;
								padding: 0;
								top: 0;
								transition: transform 0.15s ease;

								img {
									display: block;
									width: 100%;
								}

								&:before,
								&:after {
									display: none;
								}

								&:hover {
									transform: scale(1.1);
								}
							}
						}

						&.left-of-logo {
							padding-right: 2rem;
						}

						&.right-of-logo {
							padding-left: 2rem;
						}
					}
				}
			}

			.secondary {
				display: flex;
				justify-content: center;
				align-items: center;

				.logo-text {
					width: 10rem;
					height: 2.5rem;
					margin: 0.375rem 2rem 0;

					img {
						display: block;
						width: 100%;
					}
				}

				p {
					width: 15.875rem;
					font-size: 0.625rem;
					position: relative;
					top: -0.4375rem;
					color: ${( { theme } ) => theme.colors.transparentWhite};
					white-space: nowrap;

					span {

						&:not(:last-child):after {
							content: '■';
							position: relative;
							top: -0.0625rem;
							margin: 0 0.75rem;
							opacity: 0.5;
							font-size: 0.5625rem;
						}
					}
				}

				.address {
				}

				.contact {
					text-align: right;

					a {
						font-family: '04b03regular', monospace;
						font-size: 0.8125rem;
						text-transform: uppercase;
						position: relative;
						top: 0.0625rem;
						color: ${( { theme } ) => theme.colors.white};

						&.email:before {
							content: '';
							display: inline-block;
							margin: 0 0.5rem 0 0;
							width: 0.9375rem;
							height: 0.6875rem;
							background: url(/images/icons/pixel-email-white.svg) 50% 50% / 100% auto no-repeat;
							position: relative;
							top: 0.0625rem;
						}

						&:hover {
							text-decoration: none;
							color: ${( { theme } ) => theme.colors.green};

							&.email:before {
								background-image: url(/images/icons/pixel-email-green.svg);
							}
						}

					}
				}
			}
		}
	}
}

@keyframes circle-wipe-in {
	0% {
		visibility: hidden;
		mask: conic-gradient(from 35deg, #000 -40%, rgba(0, 0, 0, 0) 0%);
	}
	1% {
		visibility: visible;
	}
	7% {
		mask: conic-gradient(from 35deg, #000 -30%, rgba(0, 0, 0, 0) 10%);
	}
	14% {
		mask: conic-gradient(from 35deg, #000 -20%, rgba(0, 0, 0, 0) 20%);
	}
	21% {
		mask: conic-gradient(from 35deg, #000 -10%, rgba(0, 0, 0, 0) 30%);
	}
	29% {
		mask: conic-gradient(from 35deg, #000 0%, rgba(0, 0, 0, 0) 40%);
	}
	36% {
		mask: conic-gradient(from 35deg, #000 10%, rgba(0, 0, 0, 0) 50%);
	}
	43% {
		mask: conic-gradient(from 35deg, #000 20%, rgba(0, 0, 0, 0) 60%);
	}
	50% {
		mask: conic-gradient(from 35deg, #000 30%, rgba(0, 0, 0, 0) 70%);
	}
	57% {
		mask: conic-gradient(from 35deg, #000 40%, rgba(0, 0, 0, 0) 80%);
	}
	64% {
		mask: conic-gradient(from 35deg, #000 50%, rgba(0, 0, 0, 0) 90%);
	}
	71% {
		mask: conic-gradient(from 35deg, #000 60%, rgba(0, 0, 0, 0) 100%);
	}
	79% {
		mask: conic-gradient(from 35deg, #000 70%, rgba(0, 0, 0, 0) 110%);
	}
	86% {
		mask: conic-gradient(from 35deg, #000 80%, rgba(0, 0, 0, 0) 120%);
	}
	93% {
		mask: conic-gradient(from 35deg, #000 90%, rgba(0, 0, 0, 0) 130%);
	}
	100% {
		visibility: visible;
		mask: conic-gradient(from 35deg, #000 100%, rgba(0, 0, 0, 0) 140%);
	}
}

@keyframes circle-wipe-out {
	0% {
		visibility: visible;
		mask: conic-gradient(from 35deg, #000 100%, rgba(0, 0, 0, 0) 140%);
	}
	7% {
		mask: conic-gradient(from 35deg, #000 90%, rgba(0, 0, 0, 0) 130%);
	}
	14% {
		mask: conic-gradient(from 35deg, #000 80%, rgba(0, 0, 0, 0) 120%);
	}
	21% {
		mask: conic-gradient(from 35deg, #000 70%, rgba(0, 0, 0, 0) 110%);
	}
	29% {
		mask: conic-gradient(from 35deg, #000 60%, rgba(0, 0, 0, 0) 100%);
	}
	36% {
		mask: conic-gradient(from 35deg, #000 50%, rgba(0, 0, 0, 0) 90%);
	}
	43% {
		mask: conic-gradient(from 35deg, #000 40%, rgba(0, 0, 0, 0) 80%);
	}
	50% {
		mask: conic-gradient(from 35deg, #000 30%, rgba(0, 0, 0, 0) 70%);
	}
	57% {
		mask: conic-gradient(from 35deg, #000 20%, rgba(0, 0, 0, 0) 60%);
	}
	64% {
		mask: conic-gradient(from 35deg, #000 10%, rgba(0, 0, 0, 0) 50%);
	}
	71% {
		mask: conic-gradient(from 35deg, #000 0%, rgba(0, 0, 0, 0) 40%);
	}
	79% {
		mask: conic-gradient(from 35deg, #000 -10%, rgba(0, 0, 0, 0) 30%);
	}
	86% {
		mask: conic-gradient(from 35deg, #000 -20%, rgba(0, 0, 0, 0) 20%);
	}
	93% {
		visibility: visible;
		mask: conic-gradient(from 35deg, #000 -30%, rgba(0, 0, 0, 0) 10%);
	}
	100% {
		mask: conic-gradient(from 35deg, #000 -40%, rgba(0, 0, 0, 0) 0%);
		visibility: hidden;
	}
}




`;