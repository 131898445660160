import {Link as ScrollLink} from 'react-scroll';
import {HashLink} from 'react-router-hash-link';


/**
 *
 * @param {{
 * 		path: string,
 * 		hash: string,
 * 		children: 	JSX.Element,
 * 		onClick: function|null,
 * 		className; string
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 * @see https://github.com/fisshy/react-scroll
 */
const SmoothScrollHashLink = ( { path, hash, children, onClick = null, className = '' } ) =>
{
	if (path !== window.location.pathname) {
		return (
			<HashLink to={`${path}#${hash}`}>
				{children}
			</HashLink>
		);
	}

	// smooth: "easeInOutQuart"
	return (
		<ScrollLink
			to={hash}
			href={`#${hash}`}
			spy={true}
			//hashSpy={true}
			smooth="easeInOutCubic"
			duration={800}
			onClick={onClick}
			className={className}
			delay={0}
		>
			{children}
		</ScrollLink>
	);
};


/**
 *
 */
export default SmoothScrollHashLink;