import {useState} from 'react';

// styles
import {PageComponent} from './styles';

// components
import Header from '../Header';
import Footer from '../Footer';
import SiteBg from '../SiteBg';
import ContactModal from '../ContactModal';
import ScrollToTop from '../../helpers/ScrollToTop';


/**
 *
 * @param {{
 * 		page: JSX.Element,
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const Page = ( { page } ) =>
{
	const [contactOpen, setContactOpen] = useState( false );
	const Page                          = page;
	const closeContactModal             = () => setContactOpen( false );
	const openContactModal              = e =>
	{
		e.preventDefault();
		setContactOpen( true )
	};


	return (
		<>
			<ScrollToTop />
			<SiteBg />
			<PageComponent id="top">
				<Header
					openContactModal={openContactModal}
					closeContactModal={closeContactModal}
				/>
				<main>
					<Page />
				</main>
				<Footer
					openContactModal={openContactModal}
					closeContactModal={closeContactModal}
				/>
				<ContactModal
					show={contactOpen}
					handleClose={closeContactModal}
				/>
			</PageComponent>
		</>
	);
};


export default Page;