// noinspection HtmlUnknownAnchorTarget

import {useEffect, useRef} from 'react';
import {Col, Container, Row} from 'react-bootstrap';

// styles
import {HomePageComponent} from './styles';

// data
import getPortfolioItems from '../../../data/portfolio-items';
import getSkills from '../../../data/skills';

// components
import PageSection from '../../components/PageSection';
import PortfolioCardGroup from '../../components/Portfolio/CardGroup';
import SkillList from '../../components/SkillList';
import {Link} from 'react-scroll';


/**
 *
 * @return {JSX.Element}
 * @constructor
 */
const HomePage = () =>
{
	const portfolioItems         = getPortfolioItems();
	const skills                 = getSkills();
	const yearBusinessStarted    = 2012;
	const yearFirstWebsiteMade   = yearBusinessStarted - 10;
	const yearsSinceFirstWebsite = new Date().getFullYear() - yearFirstWebsiteMade;
	const introCopyRef           = useRef();
	const keyboardRef            = useRef();

	useEffect( () =>
	{
		const keyboardElem  = keyboardRef.current;
		const introCopyElem = introCopyRef.current;

		// intro elements on scroll

		if (keyboardElem instanceof HTMLElement && introCopyElem instanceof HTMLElement) {

			const initialTop        = keyboardElem.getBoundingClientRect().top + document.documentElement.scrollTop;
			const keyboardMinTop    = -100;
			const keyboardMinRotate = 45;
			const keyboardMaxRotate = 110;

			window.addEventListener( 'scroll', () =>
			{
				// keyboard

				const keyboardTop        = keyboardElem.getBoundingClientRect().top;
				const keyboardCurrentTop = keyboardTop < keyboardMinTop ? keyboardMinTop : keyboardTop;
				const keyboardRange      = initialTop - keyboardMinTop;
				const keyboardDiff       = initialTop - keyboardCurrentTop;
				const keyboardPercent    = keyboardDiff / keyboardRange;
				const keyboardRotate     = keyboardMinRotate + ((keyboardMaxRotate - keyboardMinRotate) * keyboardPercent);
				const keyboardScale      = 100 + (window.scrollY * 0.15);

				keyboardElem.style.transform = 'perspective(620px) rotate3d(1, 0, 0, ' + keyboardRotate + 'deg) scale(' + keyboardScale + '%)';

				// intro

				const introOffsetY = window.scrollY * 0.35;
				const introScale   = 100 + (window.scrollY * 0.05);
				const introRotate  = window.scrollY * -0.005;

				introCopyElem.style.transform = 'perspective(620px) rotate3d(1, 0, 0, ' + introRotate + 'deg) translate(0, ' + introOffsetY + 'px) scale(' + introScale + '%)';
			} );
		}
	}, [] );


	return (
		<HomePageComponent>

			<PageSection id="intro">
				<Container>
					<Row>
						<Col>
							<div className="blurb">
								<div className="copy" ref={introCopyRef}>
									<h1>
										Hi. I'm <strong>Aaron Fisher</strong>, a <strong>Full-Stack Web
										Developer</strong> based out of Wichita, Kansas.<br />
										I build <strong>websites</strong>, <strong>web apps</strong> and <strong>solve
										problems</strong>.
									</h1>
								</div>
								<div className="more d-block d-md-none">
									<Link
										to="skills"
										href="#skills"
										spy={true}
										smooth={true}
										aria-label="Next Section"
									>
										<div className="arrow n-0">
											<span />
										</div>
										<div className="arrow n-1">
											<span />
										</div>
										<div className="arrow n-2">
											<span />
										</div>
									</Link>
								</div>
								<img
									className="keyboard"
									src="/images/bgs/keyboard.svg"
									alt=""
									ref={keyboardRef}
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</PageSection>

			<PageSection heading="Skills" id="skills" bg="dark">
				<SkillList skills={skills} />
			</PageSection>

			<PageSection heading="Portfolio" id="portfolio">
				<PortfolioCardGroup portfolioItems={portfolioItems} />
			</PageSection>

			<PageSection heading="About Me" id="about" bg="dark">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								I first fell in love with programming around the age of 6. My family bought a <a
								href="https://en.wikipedia.org/wiki/Commodore_VIC-20" target="_blank"
								rel="noreferrer">VIC-20</a> which hooked up to our TV, and we subscribed to a monthly
								programming magazine.
								Each issue came with the (printed) source code for a game or program, and I can recall
								spending hours typing in the program for a game, getting to play it for a few minutes,
								then having to shut the computer off because it was time
								for supper or bed.
								Without any type of device to save the program, all the hard work was gone. Later we
								purchased a cassette tape recorder onto which I could store the programs, and still
								later we acquired a <a
								href="https://en.wikipedia.org/wiki/Commodore_64" target="_blank" rel="noreferrer">Commodore
								64</a> with a fancy 8" floppy drive.
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								I moved on to other interests including video editing and motion graphics and after
								college became adept with Adobe After Effects.
								With the release of After Effects version 4.1, Adobe added "Expressions": a
								Javascript-like language that would allow the programming of animations. When I began
								seeing all the cool things people were doing with this, I started teaching myself the
								syntax which led me to learning quite a bit of Javascript.
								This snowballed into wanting to learn HTML, then CSS, then PHP, etc. and I never looked
								back.
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								It's been about {yearsSinceFirstWebsite} years since I made my first website, and while
								I've had hundreds of
								hobbies and
								interests over the years, I have
								discovered that web development is what I was meant to do.
								I love web development — both the problem-solving required and the fact that it never
								becomes boring
								because there are so many aspects and technologies to juggle.
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								If you are looking for a passionate web developer, you've come to the right place;
								I hope we get the chance to work together.
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<cite>Aaron Fisher</cite>
							</p>
						</Col>
					</Row>
				</Container>
			</PageSection>

			<PageSection heading="Philosophy" id="philosophy">
				<Container>
					<Row>
						<Col md={{ span: 8, offset: 2 }}>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<strong>I strongly believe</strong> in creating
								websites that are easy to
								navigate, user friendly, as lightweight as possible, written with semantic, validating,
								accessible HTML, and cross-browser compatible. I also believe in being overly cautious
								and meticulous about website security.
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<strong>I pride myself</strong> in being able to adapt to a wide range of
								demands, project specifications, and coding styles.
							</p>
							<p
								data-aos="fade-up"
								data-aos-duration="1000"
							>
								<strong>I thrive</strong> on solving problems, learning new technologies, and
								helping people find the best solution for their needs (even if that means referring him
								or her to someone else).
							</p>
						</Col>
					</Row>
				</Container>
			</PageSection>

		</HomePageComponent>
	);
};


/**
 *
 */
export default HomePage;