import styled, {css} from 'styled-components';


export const PageSectionComponent = styled.div`

	section {
		position: relative;
		padding: 10rem 0 6rem 0;

		h2 {
			position: relative;
			text-transform: uppercase;
			text-align: center;
			margin: 0 0 4rem 0;
			font-size: 2.25rem;
			color: ${( { theme } ) => theme.colors.white};
			font-family: ${( { theme } ) => theme.typography.fonts.pixel};
			text-shadow: 0.125rem 0.125rem 0 #001a1a, 0.25rem 0.25rem 0 #001a1a;

			span {
				display: inline-block;
			}


			.bracket {
				position: relative;
				background: url(/images/icons/pixel-bracket-white.svg) 50% 50% / 100% auto repeat-x;
				width: 1.5rem;
				height: 2.5rem;
				margin: -0.5625rem 2rem -0.5625rem 0;
				opacity: 0.15;
				mix-blend-mode: color-dodge;

				${( { theme } ) => css`
					@media (${theme.mediaQueries.mdUp}) {
						margin: -0.5625rem 2rem;
					}
				`};
			}

			.right .bracket {
				background-image: url(/images/icons/pixel-bracket-reverse-white.svg);
				right: 0.375rem;
				margin: -0.5625rem 0 -0.5625rem 2rem;

				${( { theme } ) => css`
					@media (${theme.mediaQueries.mdUp}) {
						margin: -0.5625rem 2rem;
					}
				`};
			}
		}

		&.dark {
			background: rgba(0, 0, 0, 0.25);

			.bracket {
				//margin: -0.5625rem 2rem;
			}
		}
	}

`;