import styled from 'styled-components';


export const SiteBgComponent = styled.div`

	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 0;
		//background: ${( { theme } ) => theme.colors.darkGrey} url(/images/bgs/site-bg.jpg) 50% 50% / cover no-repeat;+
	background: ${( { theme } ) => theme.colors.darkAqua};

	.grid-light {
		background: 50% 50% / cover no-repeat;
		position: fixed;
		top: 3.125rem;
		left: 3.125rem;
		width: 6.25rem;
		aspect-ratio: 1;
		mix-blend-mode: color-dodge;
		opacity: 0.55;

		&.small {
			width: 18.75rem;
		}

		&.medium {
			width: 34.5rem;
		}

		&.large {
			width: 48.375rem;
		}

		&.green {
			background-image: url(/images/bgs/grid-light-green-large.png);
		}

		&.blue {
			background-image: url(/images/bgs/grid-light-blue-large.png);
		}

		// todo
		&.TEST {
			outline: 0.0625rem solid #fff;
		}
	}
`;