/**
 *
 * @return {SkillSet[]}
 */
const getSkills = () =>
{
	// todo - go through some job descriptions and find more 'buzzwords'

	return [
		{
			category: 'Languages',
			skills:   [
				'JavaScript',
				'PHP',
				'HTML',
				'CSS / SASS',
				'SQL',
			]
		},
		{
			category: 'Frameworks',
			skills:   [
				'Symfony',
				'Next.js',
				'Bootstrap',
				'Foundation',
				'Amplify',
			]
		},
		{
			category: 'CMS Platforms',
			skills:   [
				'WordPress',
				'Craft',
				'Kontent',
				'Concrete',
			]
		},
		{
			category: 'Libraries',
			skills:   [
				'React',
				'Symfony Components',
				'Doctrine',
				'Fabric.js',
				'Chart.js',
				'Monolog',
				'Handlebars',
				'Mustache',
				'Browser Kit / Panther',
				'Twig',
				'jQuery',
			]
		},
		{
			category: 'APIs',
			skills:   [
				'Google Maps',
				'Google Places',
				'Google Drive',
				'Stripe',
				'Authorize.net',
				'PayPal',
				'Facebook',
				'Twilio',
				'Mailchimp',
				'Weather.gov',
				'Custom',
			]
		},
		{
			category: 'OS & Software',
			skills:   [
				'Linux',
				'Windows',
				'Git',
				'Apache',
				'Yarn',
				'NPM',
				'Composer',
				'JetBrains IDEs',
				'Adobe Creative Suite',
				'Microsoft Office',
			]
		},
	];
};


/**
 *
 */
export default getSkills;