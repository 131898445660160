/** Color Related */

const colors = {
	white:            '#fff',
	black:            '#000',
	darkGrey:         '#353535',
	darkerGrey:       '#282828',
	lightGrey:        '#a6a6a6',
	blue:             '#33a7df',
	green:            '#6bbe46',
	modalBlack:       'rgba(0, 0, 0, 0.5)',
	darkAqua:         'rgba(0, 27, 27, 1)',
	transparentWhite: 'rgba(255, 255, 255, 0.5)',
};

const gradients = {
	//blueBrightToDarkRL: `linear-gradient(270deg, ${colors.brightBlue2} 0%, ${colors.darkBlue2} 100%) 0% 0% no-repeat`,
	//blueBrightToDarkTB: `linear-gradient(180deg, ${colors.brightBlue} 0%, ${colors.darkBlue2} 100%) 0% 0% no-repeat`,
};


/** Media Queries */

const breakpoints = {
	xs:  1,
	sm:  576,
	md:  768,
	lg:  992,
	xl:  1200,
	xxl: 1400
};

/**
 *
 * @type {{smUp: string, smDown: string, mdUp: string, lgUp: string, xxlUp: string, xsDown: string, xlUp: string, lgDown: string, mdDown: string, xxlDown: string, xsUp: string, xlDown: string}}
 */
const mediaQueries = {};

Object.keys( breakpoints ).forEach( size =>
{
	const width = breakpoints[size];

	mediaQueries[`${size}Up`]   = `min-width: ${width}px`;
	mediaQueries[`${size}Down`] = `max-width: ${width}px`;
} );


/** Typography Related */

const typography = {
	fonts: {
		primary:   "'Maven Pro', sans-serif",
		secondary: "'Fira Code', monospace",
		pixel:     "'04b03regular', monospace",
	},
};


/** Theme Export Object (what is made available in style files) */

export const theme = {
	colors,
	gradients,
	breakpoints,
	mediaQueries,
	typography,
};



