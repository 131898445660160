import React, {useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Alert, Form, Spinner} from 'react-bootstrap';

// styles
import CloseButton from '../CloseButton';

// components
import {ContactModalComponent} from './styles';


/**
 *
 * @param {{
 * 		show: boolean,
 * 		handleClose: function,
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const ContactModal = ( { show, handleClose } ) =>
{
	const initialFormFields                         = {
		name:    {
			value: '',
			ref:   useRef(),
		},
		email:   {
			value: '',
			ref:   useRef(),
		},
		message: {
			value: '',
			ref:   useRef(),
		}
	};
	const formActions                               = {
		'development': 'http://localhost:8008/actions/contact/',
		'production':  '/actions/contact/',
	};
	const formAction                                = formActions[process.env.NODE_ENV];
	const [formFields, setFormFields]               = useState( initialFormFields );
	const [formSubmitting, setFormSubmitting]       = useState( false );
	const [formValidated, setFormValidated]         = useState( false );
	const [formComplete, setFormComplete]           = useState( false );
	const [formErrorMessages, setFormErrorMessages] = useState( [] );
	const formRef                                   = useRef();

	const handleInputChange = e =>
	{
		const value     = e.target.value;
		const name      = e.target.name;
		const newValues = { ...formFields };

		newValues[name].value = value;
		setFormFields( newValues );
	};
	const submit            = e =>
	{
		e.preventDefault();

		const form        = formRef.current;
		const formData    = new FormData( form );
		const fetchParams = {
			method: 'POST',
			body:   formData,
		};

		if (form instanceof HTMLFormElement) {

			if (form.checkValidity() !== false) {

				setFormSubmitting( true );

				fetch( formAction, fetchParams )
					.then( data => data.json() )
					.then( /** @param {FormResponse} response */response =>
					{
						setFormSubmitting( false );

						if (response.success) {

							setFormComplete( true );

						} else {

							setFormErrorMessages( response.errorMessages );

							response.fieldErrors.forEach( err =>
							{
								const field = formFields[err.name];

								if (field.ref.current instanceof HTMLElement) {
									field.ref.current.setCustomValidity( 'bad' );
								}
							} );
						}
					} )
					.catch( error =>
					{
						setFormSubmitting( false );
						console.error( 'Error:', error );
						alert( 'An unknown error occurred' );
					} );
			}

			setFormValidated( true );
		}
	};


	return (
		<Modal show={show} onHide={handleClose}>
			<ContactModalComponent id="contact">
				<Form
					action={formAction}
					ref={formRef}
					noValidate
					validated={formValidated}
					onSubmit={e => submit( e )}
				>
					<Modal.Header>
						<Modal.Title as="h2">Contact</Modal.Title>
						<CloseButton onClick={handleClose} />
					</Modal.Header>
					<Modal.Body>
						<div className="address">
							<p>
								<span>7804 E. Funston Ste 216</span>
								<span>Wichita, KS 67207</span>
								<span>316.573.9984</span>
							</p>
						</div>
						{formComplete
						 ?
						 <>
							 <Alert variant="success">
								 <h3>Thank you</h3>
								 <p>
									 Your message has been sent!
								 </p>
							 </Alert>
						 </>
						 :
						 <>
							 {formErrorMessages.map( ( msg, key ) =>
								 <Alert variant="danger" key={key}>
									 <p>
										 {msg}
									 </p>
								 </Alert>
							 )}
							 <Form.Group className="mb-4" controlId="contactName">
								 <Form.Label className="required">Name</Form.Label>
								 <Form.Control
									 type="text"
									 name="name"
									 value={formFields.name.value}
									 ref={formFields.name.ref}
									 onChange={e => handleInputChange( e )}
									 required
								 />
								 <Form.Control.Feedback type="invalid">
									 Please enter your name.
								 </Form.Control.Feedback>
							 </Form.Group>

							 <Form.Group className="mb-4" controlId="contactEmail">
								 <Form.Label className="required">Email Address</Form.Label>
								 <Form.Control
									 type="email"
									 name="email"
									 value={formFields.email.value}
									 ref={formFields.email.ref}
									 onChange={e => handleInputChange( e )}
									 required
								 />
								 <Form.Control.Feedback type="invalid">
									 Please enter a valid email address.
								 </Form.Control.Feedback>
							 </Form.Group>

							 <Form.Group className="mb-4" controlId="contactMessage">
								 <Form.Label className="required">Message</Form.Label>
								 <Form.Control
									 as="textarea"
									 rows="4"
									 name="message"
									 value={formFields.message.value}
									 ref={formFields.message.ref}
									 onChange={e => handleInputChange( e )}
									 required
								 />
								 <Form.Control.Feedback type="invalid">
									 Please enter your message.
								 </Form.Control.Feedback>
							 </Form.Group>

							 {/* todo - recaptcha */}
						 </>
						}


					</Modal.Body>
					<Modal.Footer>
						{!formComplete &&
							<Button
								variant="outline-primary"
								type="submit"
								disabled={formSubmitting}
							>
								Send Message
								{formSubmitting &&
									<div className="submitting">
										<Spinner animation="border" size="sm" />
									</div>
								}
							</Button>
						}
					</Modal.Footer>
				</Form>
			</ContactModalComponent>
		</Modal>
	);
};


/**
 *
 */
export default ContactModal;