/**
 *
 * @return {[PortfolioItem]}
 */
const getPortfolioItems = () =>
{
	const items = [
		{
			'name':     'Eagle Senior Living',
			'clients':  [
				{
					'name': 'Inspire Senior Marketing',
					'url':  'https://www.inspireseniormarketing.com/'
				}
			],
			'handle':   'esl',
			'tags':     [
				'Custom Wordpress',
				'Backend',
				'Frontend',
				'Google Maps API'
			],
			'copy':     '<p>The Eagle Senior Living website is a custom-built WordPress site containing a corporate presence along with multiple community "mini-sites".</p><p>The site employs several front-end UI elements such as Google Maps along with geolocation services, Virtual Tours viewers, slideshows, galleries, etc.</p>',
			'branding': {
				color:   '#1d384c',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Eagle Senior Living is a non-profit organization with multiple Senior Living communities around the US',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'The site is actually multiple sites in one, with the corporate site and mini-sites for each of their communities',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': 'The admin area was custom built to make it as easy as possible to add and edit multiple communities',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				},
				{
					'caption': 'Each community mini-site\'s content can be edited from a single page',
					'sizes':   {
						'xs': '4-default.png',
						//'md': '4-medium.png',
						//'lg': '4-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Corporate Site',
					'url':   'https://www.eagleseniorliving.org/'
				},
				{
					'title': 'Single Community Site',
					'url':   'https://www.eagleseniorliving.org/locations/greenwood-place/'
				}
			]
		},
		{
			'name':     'Review API',
			'clients':  [
				{
					'name': 'Internal'
				}
			],
			'handle':   'review-api',
			'tags':     [
				'Symfony',
				'Facebook API',
				'Headless Browser (Chromedriver)',
				'Symfony Panther',
				'Custom Webservice API',
				'Backend',
				'SaaS'
			],
			'copy':     '<p>Review API is a custom, in-house <dfn title="Software as a Service">SaaS</dfn> app that aggregates reviews from multiple data sources (Google, Facebook, etc) and provides an easy-to-implement javascript widget that displays the accumulated reviews on a client\'s website.</p><p>The widgets are framework-agnostic and implemented via a simple piece of copy/paste javascript which are always up-to-date with the daily-aggregated reviews.</p>',
			'branding': {
				color:   '#f7941d',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Review API provides aggregated review widgets that can be placed onto any website',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'Wichita Surgical Specialists uses one of the widgets on their "Reviews" page to show Google, Facebook and HealthGrades reviews',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': 'Abay Neuroscience Center uses another widget to display reviews of their physicians',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				},
				{
					'caption': 'Clients\' Facebook reviews are retrieved through the Facebook API, via a Business-licensed Facebook app',
					'sizes':   {
						'xs': '4-default.png',
						//'md': '4-medium.png',
						//'lg': '4-large.png'
					}
				},
				{
					'caption': 'The Facebook API integration uses their SDK to connect to Clients\' Facebook business pages',
					'sizes':   {
						'xs': '5-default.png',
						//'md': '5-medium.png',
						//'lg': '5-large.png'
					}
				},
				{
					'caption': 'Google Reviews are retrieved via a Chromedriver Headless Browser implementation using Symfony Panther',
					'sizes':   {
						'xs': '6-default.png',
						//'md': '6-medium.png',
						//'lg': '6-large.png'
					}
				},
				{
					'caption': 'Reviews are aggregated daily via cron jobs, and all facets of the app can be controlled through a custom admin interface',
					'sizes':   {
						'xs': '7-default.png',
						//'md': '7-medium.png',
						//'lg': '7-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Example',
					'url':   'https://www.wsspa.com/patient-reviews/'
				}
			]
		},

		{
			'name':     'SpiceJar',
			'clients':  [
				{
					'name': 'Internal/Hobby'
				}
			],
			'handle':   'spicejar',
			'tags':     [
				'Symfony',
				'React',
				'Git Hook Deployment',
				'Composer',
				'NPM/Yarn',
				'Webpack',
				'Tesseract OCR',
				'Custom API'
			],
			'copy':     '<p>SpiceJar is a recipe web app I began writing years ago to manage the hundreds of recipes I had scattered in various formats and locations.  I began rewriting it in late 2019 to modernize it using a Symfony and React stack.</p><p>Deployment is done via custom Bash scripts that utilize Git Hooks, run remote Composer commands / Doctrine migrations and use <a href="https://linux.die.net/man/1/rsync" target="_blank" rel="nofollow">rsync</a> to transfer compiled frontend assets.</p>',
			'branding': {
				color:   '#be2a22',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'SpiceJar is a hobby web app that I built to aggregate all my recipes into a single location',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'The app parses units of measurement and times within each recipe and allows users to set multiple timers with text message notifications',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': 'It also provides an OCR service that allows users to convert pictures of recipe book pages into structured text data',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				},
				{
					'caption': 'The project is written using a Symfony 5 backend with a React front',
					'sizes':   {
						'xs': '4-default.png',
						//'md': '4-medium.png',
						//'lg': '4-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'https://spicejar.recipes'
				}
			]
		},
		{
			'name':     'Water Is Awesome',
			'clients':  [
				{
					'name': 'RBMM',
					'url':  'https://richards.com/about-us/additional-practices/rbmm/'
				},
				{
					'name': 'Tarrant Regional Water District',
					'url':  'https://www.trwd.com/'
				}
			],
			'handle':   'wia',
			'tags':     [
				'Craft CMS',
				'Backend',
				'Frontend'
			],
			'copy':     '<p>The public-facing site for Tarrant Regional Water District\'s "Water Is Awesome" initiative.</p><p>Content is populated via a custom Craft CMS backend and an API integration with their watering advice generation web app. </p>',
			'branding': {
				color:   '#00aad2',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Water Is Awesome is a water conservation site for the Dallas/Fort Worth area',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'A customized and branded Craft CMS admin panel was included in this project',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'https://waterisawesome.com/'
				}
			]
		},
		{
			'name':     'Watering Advice Web App',
			'clients':  [
				{
					'name': 'Tarrant Regional Water District',
					'url':  'https://www.trwd.com/'
				}
			],
			'handle':   'wia-app',
			'tags':     [
				'Weather.gov API',
				'Twilio API',
				'Mailchimp API',
				'Google Maps API',
				'GDAL',
				'Chart.js',
				'Fabric.js',
				'Backend',
				'Frontend'
			],
			'copy':     '<p>A watering advice generation app that populates the public-facing website(s) and handles subscriber sign-ups and SMS/email notifications.</p><p>The app aggregates rainfall and evapotranspiration data from various sources, interpolates the data via <a href="https://gdal.org/" target="_blank" rel="noopener">GDAL</a>, applies a raster algebra algorithm to the data implementing a Voronoi Tessellation, and ultimately generates vector rainfall and watering advice recommendation maps.</p>',
			'branding': {
				color:   '#84b51e', // #355e73
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'The overview screen of the app control panel',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'Watering advice maps can be created with complete customization and are generated and rendered in the browser',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': 'Maps can be exported in jpg, png and svg format using custom templates for social media, marketing and archival purposes',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				},
				{
					'caption': 'The app continuously connects with Weather.gov, Texas A&M AgriLife, Twilio, MailChimp, and several other services',
					'sizes':   {
						'xs': '4-default.png',
						//'md': '4-medium.png',
						//'lg': '4-large.png'
					}
				},
				{
					'caption': 'Email templates and campaigns can be controlled directly through the app via a custom MailChimp API implementation',
					'sizes':   {
						'xs': '5-default.png',
						//'md': '5-medium.png',
						//'lg': '5-large.png'
					}
				},
				{
					'caption': 'The app provides its own api to populate other websites with the watering advice maps and handle subscriber sign-ups',
					'sizes':   {
						'xs': '6-default.png',
						//'md': '6-medium.png',
						//'lg': '6-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'https://waterisawesome.com/weekly-watering-advice'
				}
			]
		},
		{
			'name':     'Bankers\' Bank of Kansas',
			'clients':  [
				{
					'name': 'Mix Marketing'
				}
			],
			'handle':   'bbok',
			'tags':     [
				'Custom Wordpress',
				'Backend',
				'Frontend'
			],
			'copy':     '<p>A custom WordPress website with Agency-supplied design.</p>',
			'branding': {
				color:   '#c28c57', // #c28c57
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Bankers\' Bank provides several services for banks in Kansas',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'The admin panel is customized for the unique content present on each page',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': '',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'https://www.bbok.com/'
				}
			]
		},
		{
			'name':     'Dytek',
			'clients':  [
				{
					'name': 'INVISTA S.\u00e0 r.l.',
					'url':  'https://www.invista.com/'
				}
			],
			'handle':   'dytek',
			'tags':     [
				'Custom Wordpress',
				'Backend',
				'Frontend'
			],
			'copy':     '<p>The Dytek website is a collaboration project with design by Koch Creative.</p>',
			'branding': {
				color:   '#5d89b3', // #00428b  #f7a700
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Dytek creates chemicals and compounds which are used in other industries',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'The admin editing experience is highly customized for the Dytek\'s unique data',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': 'Online help docs for translation were included as a part of the Wordpress theme',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				},
				{
					'caption': 'Nearly 100% of all content can be edited via the CMS',
					'sizes':   {
						'xs': '4-default.png',
						//'md': '4-medium.png',
						//'lg': '4-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'https://dytek.invista.com/'
				}
			]
		},
		{
			'name':     'Out2Learn',
			'clients':  [
				{
					'name': 'Urgensee',
					'url':  'https://urgensee.com/'
				}
			],
			'handle':   'out2learn',
			'tags':     [
				'Custom Wordpress',
				'SalesForce',
				'Backend',
				'Frontend'
			],
			'copy':     '<p>Out2Learn provides after-school programs to Houston area residents.</p><p>It was a quick-turnaround project with Design/feedback supplied by two different agencies and collaborative work done with a SalesForce Consultant/Developer.</p>',
			'branding': {
				color:   '#40c1ac',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Out2Learn was a custom Wordpress theme project with a very quick turn-around due to client timelines',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'The project was a collaboration with another SalesForce developer who provided the CRM backend integration',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'http://out2learnhou.org/'
				}
			]
		},
		{
			'name':     'Wedgestone Press',
			'clients':  [
				{
					'name': 'Direct'
				}
			],
			'handle':   'wedgestone-press',
			'tags':     [
				'MSSQL',
				'XML',
				'Custom APIs',
				'Data Syncing',
				'Backend',
				'Frontend'
			],
			'copy':     '<p>The Wedgestone Press websites provide online components to supplement their printed works.</p><p>Various custom backend pieces communicate with the client\'s custom VB/MSSQL in-house application which houses all of their data.  Interfaces that closely match their in-house system have been rebuilt in web format.  </p>',
			'branding': {
				color:   '#1a7a3a',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Wedgestone Press works on several projects funded through government grants and Baylor University',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'They write and publish written works, several of which have online components',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				},
				{
					'caption': 'Internally they use a custom in-house VB/MSSQL application running on a local server to manage all their data for publication. The data can be remotely synced via a web interface to populate their various websites',
					'sizes':   {
						'xs': '3-default.png',
						//'md': '3-medium.png',
						//'lg': '3-large.png'
					}
				},
				{
					'caption': 'The data can be edited and created within a custom backend interface which closely matches their in-house native application',
					'sizes':   {
						'xs': '4-default.png',
						//'md': '4-medium.png',
						//'lg': '4-large.png'
					}
				},
				{
					'caption': 'Changes to the data are separated from the production sites and can be deployed live via a button click',
					'sizes':   {
						'xs': '6-default.png',
						//'md': '6-medium.png',
						//'lg': '6-large.png'
					}
				},
				{
					'caption': 'Export routines allow the client to provide their data in various formats to other parties',
					'sizes':   {
						'xs': '7-default.png',
						//'md': '7-medium.png',
						//'lg': '7-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site (1)',
					'url':   'https://www.browningguide.org/'
				},
				{
					'title': 'Live Site (2)',
					'url':   'https://www.browningscorrespondence.com/'
				}
			]
		},
		{
			'name':     'Nonogram',
			'clients':  [
				{
					'name': 'Self-Learning/Hobby'
				}
			],
			'handle':   'nonogram',
			'tags':     [
				'ECMAScript 6',
				'Webpack',
				'Babel',
				'Custom Algorithm',
				'No Dependencies',
				'Frontend'
			],
			'copy':     '<p>An implementation of a logic puzzle game I discovered and enjoyed a few years back.</p><p>It was rewritten in 2019 with the goal of removing all dependencies and gaining a level of proficiency with ECMAScript 6 and Webpack.</p>',
			'branding': {
				color:   '#777',
				logoUrl: '',
			},
			'images':   [
				{
					'caption': 'Nonogram is a logic puzzle game built both for fun and as a learning exercise',
					'sizes':   {
						'xs': '1-default.png',
						//'md': '1-medium.png',
						//'lg': '1-large.png'
					}
				},
				{
					'caption': 'The source is publicly available on GitHub',
					'sizes':   {
						'xs': '2-default.png',
						//'md': '2-medium.png',
						//'lg': '2-large.png'
					}
				}
			],
			'links':    [
				{
					'title': 'Live Site',
					'url':   'https://monkeyarms.github.io/nonogram/'
				},
				{
					'title': 'Github',
					'url':   'https://github.com/monkeyArms/nonogram'
				}
			]
		},
	];

	items.forEach( item =>
	{
		item.layoutId         = 'portfolio-item-' + item.handle;
		item.branding.logoUrl = `/images/portfolio/${item.handle}/logo.svg`;

		item.images.forEach( image =>
		{
			Object.keys( image.sizes ).forEach( size =>
			{
				image.sizes[size] = `/images/portfolio/${item.handle}/${image.sizes[size]}.webp`;
			} );

			image.alt = image.alt ?? '';
		} );
	} );


	return items;
};


/**
 *
 */
export default getPortfolioItems;