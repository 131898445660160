import {Card, Col, Container, Row} from 'react-bootstrap';

// styles
import {SkillListComponent} from './styles';

// components
import TagList from '../TagList';


/**
 *
 * @param {{
 * 		skills: SkillSet[],
 * 	}} props
 * @return {JSX.Element}
 * @constructor
 */
const SkillList = ( { skills } ) =>
{
	return (
		<SkillListComponent>
			<Container>
				<Row>
					{skills.map( ( skill, key ) =>
						<Col
							md={6}
							lg={4}
							className="mb-5"
							key={key}
						>
							<Card
								data-aos="flip-up"
								data-aos-duration="1000"
								data-aos-delay={key * 75}
								className={skill.skills.length > 8 && 'tall'}
							>
								<Card.Header
									as="h3"
									data-aos="header-in"
									data-aos-duration={1000}
									data-aos-delay={0}
								>
									{skill.category}
								</Card.Header>
								<Card.Body>
									<Card.Text as="div">
										<TagList tags={skill.skills} animate={true} />
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					)}
				</Row>
			</Container>
		</SkillListComponent>
	);
};


/**
 *
 */
export default SkillList;