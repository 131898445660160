// styles
import {HamburgerComponent} from './styles';


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Hamburger = ( { open, setOpen } ) =>
{
	const gridSize = 5;
	const cells    = new Array( Math.pow( gridSize, 2 ) ).fill( null );

	return (
		<HamburgerComponent>
			<button
				aria-label={open ? 'Close Menu' : 'Open Menu'}
				className={open ? 'open' : 'closed'}
				onClick={() => setOpen( !open )}
			>
				<div className="grid">
					{cells.map( ( cell, key ) =>
						<div
							key={key}
							className={`cell n-${key} row-${Math.floor( key / gridSize )}`}
						/>
					)}
				</div>
			</button>
		</HamburgerComponent>
	);
};


/**
 *
 */
export default Hamburger;

