import {createGlobalStyle, css} from "styled-components";


export const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: '04b03regular';
		src: url('/fonts/04b03/04b_03__-webfont.woff2') format('woff2'),
		url('/fonts/04b03/04b_03__-webfont.woff') format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	html {
		/*
			@see https://github.com/fisshy/react-scroll/issues/490
		 */
		scroll-behavior: auto !important;
	}

	body {
		font-family: ${( { theme } ) => theme.typography.fonts.primary};
		background: ${( { theme } ) => theme.colors.darkAqua};
		color: ${( { theme } ) => theme.colors.white};
		overflow-x: hidden;
	}

	main {
		overflow-x: hidden;
	}

	h1, h2, h3, h4, h5, h6 {
	}

	p {
		line-height: 2;
	}

	a:not(.btn) {
		color: ${( { theme } ) => theme.colors.green};
		text-decoration: none;

		&:hover {
			color: ${( { theme } ) => theme.colors.green};
			text-decoration: underline;
		}
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.btn {
		font-family: ${( { theme } ) => theme.typography.fonts.pixel};
		text-transform: uppercase;
		font-size: 18px;

		${( { theme } ) => css`
			@media (${theme.mediaQueries.smDown}) {
				font-size: 16px;
			}
		`};
	}

	.form-label.required:after {
		content: '';
		display: inline-block;
		width: 7px;
		aspect-ratio: 1;
		background: url(/images/icons/pixel-asterisk-white.svg) 50% 50% / 100% auto no-repeat;
		opacity: 0.5;
		margin: 0 0 5px 0.5rem;
	}



`