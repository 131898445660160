import styled from 'styled-components';


export const CloseButtonComponent = styled.div`

	button {
		display: block;
		width: 1.5rem;
		height: 1.5rem;
		padding: 0.5rem;
		margin: 0;
		border: none;
		background: url(/images/icons/pixel-x-white.svg) 50% 50% / auto 100% no-repeat;
		cursor: pointer;
		opacity: 0.5;
		transition: opacity 0.25s, transform 0.25s ease;

		&:hover {
			opacity: 1;
			transform: scale(1.25);
		}
	}

`;
